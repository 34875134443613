import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import "./style.scss";
import Button from "../Button/index";
import FieldSet from "../FieldSet";
import { ToastContainer, toast } from "react-toastify";
import { serviceErrorHandler } from "../../config/toastError";
import { setLoading } from "../../redux/sidebar/actions";
import { Location } from "../../services/Location";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "30px 35px",
    width: "300px",
  },
};

const AddTariffModal = ({ setAddModal, setUpdate, update }) => {
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.sidebar.loading);
  const dispatch = useDispatch();

  function closeModal() {
    setAddModal(false);
  }

  const addTariff = async (tariff_id, price) => {
    console.log("inside addTariff function", tariff_id, price);
    const formData = {
      tariff: tariff_id,
      price: price,
    };
    console.error(formData);
    dispatch(setLoading(true));
    Location.AddTariff(token, formData)
      .then((res) => {
        setUpdate(!update);
        setAddModal(false);
      })
      .catch((error) => {
        toast.error(serviceErrorHandler(error));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <Formik
      initialValues={{ tariff_id: "", price: "" }}
      onSubmit={async (values) => {
        console.log("on submit", values);
        await addTariff(values.tariff_id, values.price);
      }}
      validationSchema={yup.object().shape({
        tariff_id: yup.string().required("Tariff ID is required"),
        price: yup.string().required("Price is required"),
      })}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <Modal isOpen={true} onRequestClose={closeModal} style={customStyles}>
          <div className="msa-modal">
            <FieldSet
              type="text"
              legend="Tariff ID"
              className="text-input margin-top"
              value={values.tariff_id}
              onChange={handleChange("tariff_id")}
              error={errors.tariff_id && touched.tariff_id}
              errorText={errors.tariff_id}
            />
            <FieldSet
              type="text"
              legend="Price"
              className="text-input margin-top"
              value={values.price}
              onChange={handleChange("price")}
              error={errors.price && touched.price}
              errorText={errors.price}
            />
            <div className="action-buttons">
              <Button title="Back" outline={true} onClick={closeModal} />
              <Button loading={loading} title="Submit" onClick={handleSubmit} />
            </div>
          </div>
          <ToastContainer />
        </Modal>
      )}
    </Formik>
  );
};

export default AddTariffModal;
