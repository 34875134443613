import charging_circle from "../assets/charging-circle.svg";
import analytics_circle from "../assets/analytics-circle.svg";
import integrations_circle from "../assets/integration-circle.svg";
import type from "../assets/type.svg";
import retailer from "../assets/retailer.svg";

export const infoCardsData = [
  {
    title: "Charging Locations",
    img: charging_circle,
    link: "locations",
    para: "The Network Management Suite allows you to quickly view and manage your locations with the help of an easy to use and intuitive interface.",
  },
  {
    title: "Analytics",
    img: analytics_circle,
    link: "#",
    para: "The Analytics Suite makes it easy to quickly display powerful insights and shows how customers interact with the charging station network.",
  },
  {
    title: "Integrations",
    img: integrations_circle,
    link: "#",
    para: "The Analytics Suite makes it easy to quickly display powerful insights and shows how customers interact with the charging station network.",
  },
];

export const initialSidebarMenus = [
  {
    name: "locations",
    icon: "menu",
    hidden: false,
    disabled: false,
  },
  {
    name: "liveFeed",
    icon: "live_feed",
    hidden: false,
    disabled: false,
  },
  {
    name: "management",
    icon: "management",
    hidden: false,
    disabled: false,
  },
  {
    name: "data",
    icon: "data",
    hidden: false,
    disabled: true,
  },

  {
    name: "analytics",
    icon: "analytics",
    hidden: false,
    disabled: true,
  },
  {
    name: "setting",
    icon: "settings",
    hidden: false,
    disabled: true,
  },
];

export const subMenus = {
  locations: {
    name: "locations",
    mainTitle: "Your Locations",
    mainItems: [
      {
        title: "Charging Locations",
        route: "/locations",
      },
      {
        title: "Charging Stations",
        route: "/charging-stations",
      },
    ],
    tools: [
      {
        title: "MSA",
        route: "/msa",
        disabled: false,
      },
      {
        title: "Tariff",
        route: "/tariff",
        disabled: false,
      },
      {
        title: "Facilities",
        route: "/facilities",
        disabled: true,
      },
      {
        title: "Payment",
        route: "/payment",
        disabled: true,
      },
      {
        title: "Connector Types",
        route: "/connector-types",
        disabled: true,
      },
      {
        title: "GFX Sync",
        route: "/gfx-sync",
        disabled: true,
      },
    ],
  },
  liveFeed: {
    name: "liveFeed",
    mainTitle: "Live Feed",
    mainItems: [
      {
        title: "Notifications",
        route: "/feed-notifications",
      },
      {
        title: "API Log",
        route: "/api-log",
      },
    ],
  },
  management: {
    name: "management",
    mainTitle: "Management",
    mainItems: [
      {
        title: "Users",
        route: "/user-management",
      },
      {
        title: "Teams",
        route: "/team-management",
      },
    ],
  },
};
