import React, { useState, useEffect, useCallback } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

import "../Locations/styles.scss";
import Page from "../../components/Page";
import ActionHeader from "../../components/ActionHeader";
import Divider from "../../components/Divider";
import StatusButton from "../../components/StatusButton/index";
import TablePagination from "../../components/TablePagination";
import Icon from "./../../components/Icon/index";
import ConfirmationModal from "../../components/ConfirmationModal";
import { Location } from "../../services/Location";
import EditMsaModal from "../../components/EditMsaModal";
import AddMsaModal from "../../components/AddMsaModal";
import { setLoading } from "../../redux/sidebar/actions";
import Toggle from "react-toggle";
import "./styles.scss";
import Container from "../../components/Container";
import AddTariffModal from "../../components/AddTariffModal";

createTheme(
  "solarized",
  {
    text: { primary: "white" },
    background: { default: "#013131" },
    divider: { default: "#002626" },
  },
  "dark"
);

const Tariff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.sidebar.loading);
  const [AddModal, setAddModal] = useState(false);
  const [page, setPage] = useState(1);
  const [noOfRows, setNoOfRows] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pending, setPending] = useState(false);
  const [sort, setSort] = useState("");
  const [orderType, setOrderType] = useState("asc");
  const [currentData, setCurrentData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedTariffId, setSelectedTariffId] = useState(null);

  useEffect(() => {
    setPending(true);
    fetchTariffs().finally(() => setPending(false));
  }, [page, searchText, noOfRows, update]);

  async function fetchTariffs() {
    return Location.Fetch_All_Tariff(
      token,
      page,
      noOfRows,
      searchText,
      sort,
      orderType
    )
      .then(({ data }) => {
        setTotalCount(data.totalRows);
        setCurrentData(data);
        dispatch(setLoading(false));
      })
      .catch((error) => console.log("Error fetching tariffs", error.response));
  }

  const onDeleteTariff = () => {
    if (!selectedTariffId) return;
    Location.DeleteTariff(token, selectedTariffId)
      .then(() => {
        setShowConfirmationModal(false);
        setUpdate(!update);
        toast.success("Tariff Deleted Successfully");
      })
      .catch((err) => {
        setShowConfirmationModal(false);
        toast.error(err.response?.data?.msg || "Error deleting tariff");
      });
  };

  const columns = [
    { name: "ID", selector: (row) => row.id, sortable: true, grow: 1 },
    { name: "Tariff ID", selector: (row) => row.tarif_id, grow: 1 },
    { name: "Price", selector: (row) => `${row.price}`, grow: 1 },
    {
      name: "Actions",
      cell: (row) => (
        <button
          className="delete-btn"
          onClick={() => {
            setSelectedTariffId(row.id);
            setShowConfirmationModal(true);
          }}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div>
      {showConfirmationModal && (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={() => setShowConfirmationModal(false)}
          headingTitle="Are you sure you want to delete this Tariff?"
          onClickNo={() => setShowConfirmationModal(false)}
          onClickYes={onDeleteTariff}
        />
      )}
      <Container
        showSearchBar
        onSearchChange={debounce((e) => setSearchText(e.target.value), 300)}
      >
        <div className="location-container">
          <ActionHeader
            title="Tariff"
            showButtons
            leftButtonTitle="Create"
            leftButtonClick={() => setAddModal(true)}
            leftButtonDisable={userData.role === "MEMBER"}
          />
          {AddModal && (
            <AddTariffModal
              setAddModal={setAddModal}
              setUpdate={setUpdate}
              update={update}
            />
          )}
          <Divider />
          <div className="table-div">
            <DataTable
              className="rdt_Table"
              columns={columns}
              data={currentData}
              progressPending={pending}
              onSort={(column, sortDirection) => {
                setSort(column.sortField);
                setOrderType(sortDirection);
              }}
              sortServer
              highlightOnHover
              theme="solarized"
              responsive
              pagination
              paginationPerPage={noOfRows === "All" ? totalCount : noOfRows}
            />
          </div>
        </div>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default Tariff;
