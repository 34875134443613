import React, { useState, useEffect, useCallback } from "react";
import "./styles.scss";
import ActionHeader from "../../components/ActionHeader";
import Divider from "../../components/Divider";
import debounce from "lodash.debounce";
import { useSelector, useDispatch } from "react-redux";
import { StatusRegister } from "../../services/StatusRegister";
import TablePagination from "../../components/TablePagination";
import { setLoading } from "../../redux/sidebar/actions";
import Container from "../../components/Container";

const Apilog = () => {
  const dispatch = useDispatch();

  // redux states
  const token = useSelector((state) => state.auth.token);

  const [page, setPage] = useState(1);
  const [noOfRows, setNoOfRows] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [showRowsDropdown, setShowRowsDropdown] = useState(false);
  const [from, setFrom] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [update, setUpdate] = useState(false);
  const [dates, setDates] = useState([]);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    setPending(true);
    if (dates.length > 0) {
      StatusRegister.FetchStatusRegister(
        token,
        page,
        noOfRows,
        searchText,
        dates
      )
        .then(({ data }) => {
          setCurrentData(data.data);
          setTotalCount(data.totalRows);
          if (page > 1) {
            setFrom((page - 1) * noOfRows + 1);
          } else {
            setFrom(1);
          }
        })
        .catch((error) => console.log("error here", { error }))
        .finally(() => {
          setPending(false);
          dispatch(setLoading(false));
        });
    }
  }, [page, searchText, noOfRows, update, dates]);

  const onHandlePageClick = (selectedPage) => {
    setPage(selectedPage);
  };

  const onChangeRowsPerPage = () => {};

  const setSearchInput = (event) => {
    setSearchText(event.target.value);
  };
  // debounce function to wait for a little time after user stop writing and then run setSearchInput function to search location
  const handleInputChange = useCallback(debounce(setSearchInput, 300), []);

  return (
    <Container showSearchBar onSearchChange={handleInputChange}>
      <div className="api-log">
        <ActionHeader
          title={"Status Log"}
          showCalendar
          setDates={setDates}
          dates={dates}
        />
        <Divider />
        <div className="table-div">
          {pending ? (
            <>
              <div className="pending">
                <p>Loading...</p>
              </div>
            </>
          ) : currentData.length === 0 ? (
            <div className="no-records">
              <p>There are no records to displayx</p>
            </div>
          ) : (
            <>
              <table>
                <tbody>
                  <tr>
                    <td>#</td>
                    <td>LocationID</td>
                    <td>Location name</td>
                    <td>EVSEID</td>
                    <td>Status</td>
                    <td>Date timeStamp</td>
                  </tr>
                  {currentData?.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          {page > 1 ? (page - 1) * 10 + index + 1 : index + 1}
                        </td>
                        <td>{item.LocationID}</td>
                        <td>{item.location?.name}</td>
                        <td>{item.EvseID}</td>
                        <td>{item.Status}</td>
                        <td>{item.sys_date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
        <div className="pagination-div">
          <TablePagination
            page={page}
            totalCount={totalCount}
            itemsPerPage={noOfRows}
            onHandlePageClick={(selectedPage) =>
              onHandlePageClick(selectedPage)
            }
            from={from}
            currentData={currentData}
            setShowRowsDropdown={setShowRowsDropdown}
            showRowsDropdown={showRowsDropdown}
            noOfRows={noOfRows}
            onChangeRowsPerPage={onChangeRowsPerPage}
            setPage={setPage}
            setNoOfRows={setNoOfRows}
          />
        </div>
      </div>
    </Container>
  );
};

export default Apilog;
